import React from "react";
import { navigate } from "gatsby";

import Layout from "../components/layout";

const NotFoundPage = () => {
	return (
		<Layout>
			<div className="aboveFold">
				<h1>Oopsie!</h1>
				<p>We couldn’t find the page you were looking for.</p>
			</div>
		</Layout>
	);
};

export default NotFoundPage;
